import { FC, createElement } from 'react';
import classNames from 'classnames';
import { ITypographyProps } from './interfaces';
import styles from './typography.module.scss';

const headers = ['h1', 'h2', 'h3', 'h4'];

export const Typography: FC<ITypographyProps> = ({ as = 'p', children, variant, className }) => {
  const fontStyles = () => {
    switch (true) {
      case variant === 'h1':
        return styles.header__xl;
      case variant === 'h2':
        return styles.header__l;
      case variant === 'h3':
        return styles.header__m;
      case variant === 'h4':
        return styles.header__s;
      case variant === 'caps':
        return styles['caps-lock'];
      case variant === 'small':
        return styles.small;
      default:
        return styles.paragraph;
    }
  };

  return (
    createElement(
      typeof children != 'string' ? 'span' : as,
      {
        className: classNames(
          variant && headers.includes(variant) && 'raleway',
          !headers.includes(as) && 'inter', fontStyles(), className,
        ),
      },
      children)
  );
};